.tagcloud {
  // font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 650;
  margin-left: 50%;
  color: rgba(8, 253, 216, 0.846); // color: #6d1af4;
}

.tagcloud--item:hover {
  color: rgb(255, 255, 255); // color: #6d1af4;
}