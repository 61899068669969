.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    color: #fff;
      // color: #fff;
      font-size: 56px;
      line-height: 53px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
      width: 100%;
      max-width: 100%;
    
    &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #010100;
        font-size: 0px;
        position: absolute;
        margin-top: -40px;
        left: -15px;
        opacity: 0.6;
      }
    
      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 0px;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;
      }
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 400px;

    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0.2) 0,
          rgba(0, 0, 0, 1));
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      width: 100%;
      height: 100%;

    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
      width: 100%;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      margin: 12px;
      height: 40px;
      line-height: 34px;
      border: 2px solid rgba(8, 253, 216, 0.365);
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: rgba(8, 253, 216, 0.365);
    }

    &:after {
      content: "";
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.365), #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:first-child {
      &:hover:after {
        content: "Dungeonball is a full stack entertainment app for a user who doesn't understand football, but does know Dungeons and Dragons! The app converts NFL players to Dungeons and Dragons characters.";
        font-size: 15px;
        font-weight: 500;
        color: black;
        padding: 5px;
        padding-top: 50px;

      }
    }

    &:nth-child(2) {
      &:hover:after {
        content: "Whether Sweater is a Rails API that provides data for your upcoming road trip to include: travel time, forecast upon arrival, directions, and an image of your destination.";
        font-size: 15px;
        font-weight: 500;
        color: black;
        padding-top: 50px;
        padding-right: 30px;
        padding-left: 2px;
      }
    }

    &:nth-child(3) {
      &:hover:after {
        content: 'PupTrainer is an app for anyone who wants to train their dog. The backend was built with Rails using a GraphQL API. The API has several queries and endpoints for a seamless frontend.';
        font-size: 15px;
        font-weight: 500;
        color: black;
        padding-top: 50px;
        padding-right: 30px;
        padding-left: 2px;
      }
    }

    &:nth-child(4) {
      &:hover:after {
        content: 'Rails Engine is a business intelligence API.  Its endpoints offer data about merchants and items and can offer insight into a variety of metrics including top sellers and their items.';
        font-size: 15px;
        font-weight: 500;
        color: black;
        padding-top: 50px;
        padding-right: 30px;
        padding-left: 2px;
      }
    }

    &:hover:after {
      content: "";
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }

  .resume-button {
    color: rgb(8, 253, 216);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid rgb(8, 253, 216);
    margin-top: 25px;
    float: right;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    background-color: #7b8083;
    transition: transform 0.1s ease-out;
    box-shadow: 0 5px 0 rgba(5, 191, 163, 0.993);
    border-radius: 6px;
    margin-right: 15%;
    

    &:hover {
      box-shadow: 0 0 0 rgba(5, 191, 163, 0.638);
      background: rgba(8, 253, 216, 0.123);
      color: rgb(255, 255, 255);
      transform: translateY(5px);
    }

  }
}